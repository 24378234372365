import "./styles/Hero.css";
//ANIMATION LIBRARY
import { Fade } from "react-reveal";

const Hero = () => {
  return (
    <div className="max-cont hero-bg" style={{ height: " calc(100vh - 100px)" }}>
      {/*<div className="max-cont" style={{backgroundColor:"#1E6FAD"}}>*/}

      <div className="landing-hero">
        <div className="left-hero">
          <Fade duration={1000} left>
            <img className="index-hero-logo" src="/assets/index-logo.svg" alt="" />
          </Fade>
          <Fade delay={200} duration={1000} left>
            <img className="index-hero-sub-logo" src="/assets/index-logo-sub.svg" alt="" />

          </Fade>
        </div>
      </div>
    </div >
  );
};

export default Hero;
