import { service } from ".";

export const fetchShoppingList = (id) => {
  return new Promise((resolve, reject) => {
    let url = "/line/shopping_cart?lineId=" + id;
    service({
      url: url,
      method: "GET",
    })
      .then(async (res) => await resolve(res.data))
      .catch((error) => reject(error));
  });
};

export const updataShoppingList = (id, data) => {
  return new Promise((resolve, reject) => {
    let url = "/line/shopping_cart?lineId=" + id;
    service({
      url: url,
      method: "POST",
      data
    })
      .then(async (res) => await resolve(res.data))
      .catch((error) => reject(error));
  });
};


export const updataPurchase = (id, prime, data) => {
  return new Promise((resolve, reject) => {
    let url = "/line/purchase?prime=" + prime + "&lineId=" + id;
    service({
      url: url,
      method: "POST",
      data
    })
      .then(async (res) => await resolve(res.data))
      .catch((error) => reject(error));
  });
};

