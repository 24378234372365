import MailModal from "../../Components/MailModal/MailModal";
import "./styles/Contact.css";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { StyledFlexBox, StyledBox } from "../Styles/Shared.styles";

const Contact = () => {

  //const [disabled, setDisabled] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isScreenTooSmall, setIsScreenTooSmall] = useState(0);
  useEffect(() => {
    if (window.innerWidth <= 1024 && window.innerWidth >= 768)
      setIsScreenTooSmall(1);
    else if (window.innerWidth < 768)
      setIsScreenTooSmall(2);
  }, []);
  return (
    <Fade left duration={500}>

      <div id="contact" className="contact">
        {isScreenTooSmall === 0 ?
          <><StyledFlexBox px={5} mx={5} width="80%">
            <StyledBox className="product-box" style={{ border: "1px solid #666666" }} px={5} my={3} mx={3}>
              <img src="/assets/safety.svg" alt="" />
              <h1 style={{ fontWeight: 700 }}>品質與承諾</h1>
              <p className="text-left">
                我們承諾所提供的商品都是最好的，符合最嚴格的標準，經過嚴格的控制和認證。
              </p>
            </StyledBox>
            <StyledBox className="product-box" style={{ border: "1px solid #666666", background: 'linear-gradient(180deg, #911AAD 0%, #4C1A57 100%)', }} px={5} my={3} mx={3}>
              <img src="/assets/index-logo.svg" alt="" width="100%" />
              <p className="text-left" style={{ color: "white", fontSize: "32px" }}>
                生鮮供應整合App
              </p>
              <h1 style={{ fontWeight: 700, color: "white", fontSize: "58px" }}>五大優勢</h1>

            </StyledBox>
            <StyledBox className="product-box" style={{ border: "1px solid #666666" }} px={5} my={3} mx={3}>
              <img src="/assets/order.svg" alt="" />
              <h1 style={{ fontWeight: 700 }}>訂單管理</h1>
              <p className="text-left">
                我們的app提供方便快捷的訂單管理工具，讓您可以輕鬆加入，修改和管理您的所有訂單。
              </p>
            </StyledBox>
          </StyledFlexBox>

            <StyledFlexBox px={5} mx={5} width="80%">
              <StyledBox className="product-box" style={{ border: "1px solid #666666" }} px={5} my={3} mx={3}>
                <img src="/assets/gift.svg" alt="" />
                <h1 style={{ fontWeight: 700 }}>獨家特惠</h1>
                <p className="text-left">
                  作為我們的會員，您可以享受價格折扣和獨家優惠，讓您購物更為划算。
                </p>
              </StyledBox>
              <StyledBox className="product-box" style={{ border: "1px solid #666666" }} px={5} my={3} mx={3}>
                <img src="/assets/shop.svg" alt="" />
                <h1 style={{ fontWeight: 700 }}>供應商多元選擇</h1>
                <p className="text-left">
                  我們與許多不同的供應商合作，確保您在我們的app上能夠找到各種不同種類和產品的生鮮產品。
                </p>
              </StyledBox>
              <StyledBox className="product-box" style={{ border: "1px solid #666666" }} px={5} my={3} mx={3}>
                <img src="/assets/map.svg" alt="" />
                <h1 style={{ fontWeight: 700 }}>銷售追蹤</h1>
                <p className="text-left">
                  我們的app讓您可以追踪所有商品的銷售情況，從下單到物流跟踪，我們都會及時更新您的購物情況。
                </p>
              </StyledBox>
            </StyledFlexBox></>
          :
          <StyledBox px={3} width="100%">
            <StyledFlexBox style={{ width: "100%", height: 70.90, background: 'linear-gradient(180deg, #911AAD 0%, #4C1A57 100%)', borderRadius: 32, justifyContent: "center", alignItems: "center", cursor: "pointer" }} my={3}>
              <div style={{ color: 'white', fontSize: 40.23, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word' }}>五大優勢</div>
            </StyledFlexBox>
            <StyledBox className="product-box" style={{ border: "1px solid #666666" }} px={3} my={3}>
              <img src="/assets/safety.svg" alt="" />
              <h1 style={{ fontWeight: 700 }}>品質與承諾</h1>
              <p className="text-left">
                我們承諾所提供的商品都是最好的，符合最嚴格的標準，經過嚴格的控制和認證。
              </p>
            </StyledBox>
            <StyledBox className="product-box" style={{ border: "1px solid #666666" }} px={3} my={3}>
              <img src="/assets/order.svg" alt="" />
              <h1 style={{ fontWeight: 700 }}>訂單管理</h1>
              <p className="text-left">
                我們的app提供方便快捷的訂單管理工具，讓您可以輕鬆加入，修改和管理您的所有訂單。
              </p>
            </StyledBox>
            <StyledBox className="product-box" style={{ border: "1px solid #666666" }} px={3} my={3}>
              <img src="/assets/gift.svg" alt="" />
              <h1 style={{ fontWeight: 700 }}>獨家特惠</h1>
              <p className="text-left">
                作為我們的會員，您可以享受價格折扣和獨家優惠，讓您購物更為划算。
              </p>
            </StyledBox>
            <StyledBox className="product-box" style={{ border: "1px solid #666666" }} px={3} my={3}>
              <img src="/assets/shop.svg" alt="" />
              <h1 style={{ fontWeight: 700 }}>供應商多元選擇</h1>
              <p className="text-left">
                我們與許多不同的供應商合作，確保您在我們的app上能夠找到各種不同種類和產品的生鮮產品。
              </p>
            </StyledBox>
            <StyledBox className="product-box" style={{ border: "1px solid #666666" }} px={3} my={3}>
              <img src="/assets/map.svg" alt="" />
              <h1 style={{ fontWeight: 700 }}>銷售追蹤</h1>
              <p className="text-left">
                我們的app讓您可以追踪所有商品的銷售情況，從下單到物流跟踪，我們都會及時更新您的購物情況。
              </p>
            </StyledBox>
          </StyledBox>

        }
      </div >
    </Fade>

  );
};

export default Contact;
