import { device } from './breakpoints';
import styled from 'styled-components';

const FieldWrapper = styled.div`
  display: grid;
  gap: 10px;
  @media ${device.laptop} {
    grid-template-columns: 120px 3fr 2fr;
    gap: 0;
  }
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0px;
  color: #3f3a3a;
  @media ${device.laptop} {
    font-size: 16px;
    line-height: 19px;
    height: 32px;
    display: flex;
    align-items: center;
  }
`;

const InputWrapper = styled.div`
  max-width: 576px;
  position: relative;
  z-index: 1;
`;

const TextInput = styled.input`
  border: 1px solid #979797;
  border-radius: 8px;
  width: 100%;
  height: 32px;
  outline: 0;
  padding: 0 8px;
  &::placeholder {
    color: #d3d3d3;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0px;
  }
`;

const InputNote = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0px;
  color: #8b572a;
  padding-top: 6px;
  @media ${device.laptop} {
    text-align: right;
    padding-top: 10px;
    font-size: 16px;
    line-height: 19px;
  }
`;
const ErrorText = styled.div`
  font-size: 14px;
  font-weight: 500;
  padding-top: 3px;
  width: 100%;
  position: absolute;
  text-align: right;
  color: red;
  z-index: -1;
`;

function TextField({
  label,
  name,
  isRequired,
  placeholder,
  type,
  note,
  value,
  handleInput,
  mask,
  error,
  validate,
  id,
}) {
  return (
    <FieldWrapper>
      <Label>{label}</Label>
      <InputWrapper>
        <TextInput
          id={id || ''}
          value={value}
          onChange={handleInput(validate)}
          placeholder={placeholder}
          mask={mask ? mask : ''}
          type={type ? type : 'text'}
          name={name}
          required={isRequired}
        />
        {note && <InputNote>{note}</InputNote>}
        {error && !error.preCheck && <ErrorText>{error.msg}</ErrorText>}
      </InputWrapper>
    </FieldWrapper>
  );
}

function TapPayField({ label, id }) {
  return (
    <FieldWrapper>
      <Label>{label}</Label>
      <InputWrapper>
        <TextInput as="div" id={id || ''} />
      </InputWrapper>
    </FieldWrapper>
  );
}

const RadioWrapper = styled(InputWrapper)`
  display: flex;
  gap: 26px;
  @media ${device.laptop} {
    gap: 32px;
  }
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0px;
  cursor: pointer;
  @media ${device.laptop} {
    font-size: 16px;
    line-height: 26px;
  }
`;

const RadioInput = styled.input`
  width: 16px;
  height: 16px;
  margin-right: 6px;
  @media ${device.laptop} {
    margin-right: 8px;
  }
`;

function RadioBox({
  label,
  name,
  choices,
  value: currentValue,
  handleInput,
  error,
  validate,
}) {
  return (
    <FieldWrapper>
      <Label>{label}</Label>
      <RadioWrapper>
        {choices.map(({ label, value }, index) => (
          <RadioLabel key={index}>
            <RadioInput
              type="radio"
              value={value}
              name={name}
              checked={currentValue === value}
              onChange={handleInput(validate)}
            />
            {label}
          </RadioLabel>
        ))}
        {error && !error.preCheck && <ErrorText>{error.msg}</ErrorText>}
      </RadioWrapper>
    </FieldWrapper>
  );
}
const Wrapper = styled.div`
    padding: 0 24px;
    ${({ $name }) => $name === 'paymentForm' && `padding-top: 20px;`}
    @media ${device.laptop} {
      padding: 0;
      ${({ $name }) =>
        $name === 'paymentForm' ? `padding-top: 45px;` : `padding-top: 50px;`}
    }
  `,
  Title = styled.div`
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
    border-bottom: 1px solid #3f3a3a;
    padding-bottom: 10px;
    @media ${device.laptop} {
      padding-bottom: 16px;
    }
  `,
  FormWrapper = styled.div`
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media ${device.laptop} {
      padding-top: 25px;
      gap: 30px;
    }
  `;

const Form = ({
  schema: { name, fields },
  data,
  handleInput,
  formName,
  errors,
}) => {
  const fieldsList = {
    TextField,
    RadioBox,
    TapPayField,
  };
  return (
    <Wrapper $name={formName}>
      <Title>{name}</Title>
      <FormWrapper>
        {fields.map(({ component, options }, index) => {
          const Field = fieldsList[component];
          return (
            <Field
              key={index}
              {...options}
              value={data?.[options?.name] || ''}
              error={errors?.[options?.name] || null}
              handleInput={handleInput ? handleInput : () => {}}
            />
          );
        })}
      </FormWrapper>
    </Wrapper>
  );
};

export default Form;
