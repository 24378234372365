import "./styles/Producs.css";
// REACT ROUTER DOM -------------------
import { Link } from "react-router-dom";
// ANIMATION LIBRARY------------------
import { Fade } from "react-reveal";
import { StyledFlexBox, StyledBox } from "../Styles/Shared.styles";
import React, { useState, useEffect } from "react";

const Products = () => {
  const [isScreenTooSmall, setIsScreenTooSmall] = useState(0);
  useEffect(() => {
    if (window.innerWidth <= 1024 && window.innerWidth >= 768)
      setIsScreenTooSmall(1);
    else if (window.innerWidth < 768)
      setIsScreenTooSmall(2);
  }, []);
  return (
    <Fade left>
      <StyledFlexBox width="100%" justifyContent="right" px={4} fontSize={"14px"}>
        <StyledBox style={{ maxWidth: "700px" }}>
          <StyledBox pt={"4rem"}>
            <StyledBox fontSize="18px">
              「超有品」是餐飲業的專屬食材供應平台，為食材供應商和餐廳經營者搭建了一個便捷、透明和可靠的交流橋梁。
            </StyledBox>
            <StyledBox fontSize="18px" pt={4}>
              我們深知作為專業和負責任的供應商，每一批食材都是對質量和服務的堅定承諾，因此我們致力於提供一個平台，讓您能夠高效地管理訂單、追蹤銷售並與餐廳經營者建立長久且穩固的合作關係。
            </StyledBox>
            <StyledBox fontSize="18px" pt={4}>
              除了作為一個供應平台，「超有品」更是供應商與餐飲業者的成長夥伴，提供全方位的支持和資源，助您拓展業務、達到更高的商業目標。
            </StyledBox>
          </StyledBox>
        </StyledBox>
      </StyledFlexBox>
    </Fade>
  );
};

export default Products;
