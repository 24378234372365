import { Parallax } from "react-parallax";
import Hero from "./Hero";
import Digital from "./Digital";
import Products from "./Products";
import ProductsSub from "./ProductsSub";
import Contact from "./Contact";
import Footer from "./Footer";
import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Fade } from "react-reveal";
import Header from "../Chicken/Header/Header";
import { Helmet } from 'react-helmet';

const Landing = () => {
  const [isScreenTooSmall, setIsScreenTooSmall] = useState(0);
  const isSmallScreen = window.innerWidth < 768;
  useEffect(() => {
    if (window.innerWidth <= 1024 && window.innerWidth >= 768)
      setIsScreenTooSmall(1);
    else if (window.innerWidth < 768)
      setIsScreenTooSmall(2);
  }, []);
  return (
    <div className="container mt-header">
      <Helmet>
        <title>{"超有品"}</title>
        <meta name="description" content={"超有品；生鮮供應整合App；；開餐廳首選歡迎來到超有品，我們的使命是為餐飲店家提供優質食材及數位科技轉型，同時加速食材訂購方便性並且降低後台營運困擾"} />
        <meta name="og:description" content={"超有品；生鮮供應整合App；；開餐廳首選歡迎來到超有品，我們的使命是為餐飲店家提供優質食材及數位科技轉型，同時加速食材訂購方便性並且降低後台營運困擾"} />
      </Helmet>
      <Header />
      <Parallax
        bgImage={isSmallScreen ? "/assets/index-mobile.png" : "/assets/index.png"}
        strength={500}
        className="parallax-100"
      >
        <Hero />
      </Parallax>
      <div className="container" style={{ width: "100%" }}>
        <Digital />
      </div>
      <Parallax bgImage="/assets/bg-3.png" strength={500} style={{ width: '100%' }}>
        <Products />
      </Parallax>
      {isScreenTooSmall !== 0 &&
        <ProductsSub />
      }
      <Contact />
      {isScreenTooSmall === 0 &&
        <Footer />
      }
      <div className="foot-wrap">
        <div className="bg-purple-dark">
          <Container>
            <Row>
              <div className="bot-foot">
                <p>Copyright 2024 Super u pin All Rights Reserved. <a style={{ color: "blue" }} href="privacy">隱私權政策</a>  <a style={{ color: "blue" }} href="/">首頁</a> </p>
                <div className="social-div">
                  <a href="https://line.me/R/ti/p/%40277bgmdt" target="_blank">
                    <img src="/Assets/line.svg" alt="" />
                  </a>
                  <a href="https://facebook.com/qr?id=100082804461160" target="_blank">
                    <img src="/Assets/fb.svg" alt="" />
                  </a>
                  <a href="https://www.instagram.com/classuptw/" target="_blank">
                    <img src="/Assets/ig.svg" alt="" />
                  </a>
                </div>
              </div>
            </Row>
          </Container>
        </div>
      </div >
    </div>
  );
};

export default Landing;
