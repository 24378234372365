import { useState } from "react";
import "./App.css";
//React Router Dom--------------------
import { Route, Routes } from "react-router-dom";
//Components
import { createTheme, ThemeProvider } from "@mui/material";
import Landing from "./Pages/Landing/Landing";
import LineOrder from "./Pages/LineOrder/LineOrder";
import Linecart from "./Pages/Linecart/Linecart";
import OrderDone from "./Pages/OrderDone/OrderDone";
import Privacy from "./Pages/Privacy/Privacy";

import OrderError from "./Pages/OrderError/OrderError";
function App() {
  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            border: "1px solid #fff",
            borderRadius: "100px",
            backgroundColor: "#fff",
            zIndex: -1,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            maxWidth: "fit-content",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            width: "120px",
          },
          containedPrimary: {
            backgroundColor: "#8C9EFF",
            borderRadius: "100px",
            width: 120,
            height: 40,
            fontSize: 14,
            boxShadow: "none",
            "&.Mui-disabled": {
              backgroundColor: "#D7E0FF",
              color: "#fff",
            },
          },
          outlinedPrimary: {
            color: "#A7A7A7",
            borderColor: "#A7A7A7",
          },
          outlined: {
            borderRadius: "100px",
            width: 120,
            height: 40,
            fontSize: 14,
            boxShadow: "none",
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          head: {
            height: 80,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            color: "#505050",
          },
          body: {
            color: "#7C7C7C",
            height: 80,
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          colorPrimary: {
            color: "#8C9EFF",
            "&.Mui-checked": {
              color: "#8C9EFF",
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            color: "#505050",
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          standard: {
            alignItems: "center",
            fontSize: "14px",
          },
        },
      },
    },
  });
  const [lineId, setLineId] = useState('');

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        
        <Routes>
          <Route element={<Landing />} path="/" />
          <Route element={<Privacy />} path="/privacy" />
          <Route element={<OrderDone />} path="/orderDone" />
          <Route element={<OrderError />} path="/orderError" />
          <Route element={<LineOrder lineId={lineId} />} path="/lineOrder" />
          <Route element={<Linecart setLineId={setLineId} />} path="/linecart" />
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;
