import { Parallax } from "react-parallax";
//import Hero from "./Hero";
import Header from "../../Layout/OrderHeader/Header";
import { StyledFlexBox, StyledBox, StyledText } from "../../styles/Shared.styles";

import Footer from "./Footer";
import React, { useState, useEffect } from "react";
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import LiffComponent from './LiffComponent';


const Landing = ({ setLineId }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const idFromUrl = urlParams.get('id');
    setLineId(idFromUrl);
    navigate('/lineOrder');
  }, [location.search]);

  return (
    <div className="App">

    </div>
  );
};

export default Landing;
