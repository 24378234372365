const size = {
    mobile: '480px',
    laptop: '1280px',
    desktop: '1920px'
};

export const device = {
    mobile: `(min-width: ${size.mobile})`,
    laptop: `(min-width: ${size.laptop})`,
    desktop: `(min-width: ${size.desktop})`,
};