import "./styles/Digital.css";
import { Fade } from "react-reveal";
import React, { useState, useEffect } from "react";
import { StyledFlexBox, StyledBox } from "../Styles/Shared.styles";

const Digital = () => {
  const [isScreenTooSmall, setIsScreenTooSmall] = useState(0);
  useEffect(() => {
    if (window.innerWidth <= 1024 && window.innerWidth >= 768)
      setIsScreenTooSmall(1);
    else if (window.innerWidth < 768)
      setIsScreenTooSmall(2);
  }, []);
  return (
    <div className="digital">
      <div className="abs-curve-text">
        <div className="right-abs">
          <div className="digital-title">
            {isScreenTooSmall === 0 ?
              <Fade>
                <h2>歡迎來到超有品，我們的使命是為餐飲店家提供優質食材及數位科技轉型</h2>
                <h2>同時加速食材訂購方便性並且降低後台營運困擾</h2>
              </Fade> :
              <Fade>
                <h2>歡迎來到超有品，我們的使命是為餐飲店家提供優質食材及數位科技轉型，同時加速食材訂購方便性並且降低後台營運困擾</h2>
              </Fade>}
          </div>

          <div className="list">
            <Fade cascade bottom>
              {isScreenTooSmall === 0 ?

                <StyledFlexBox justifyContent="space-between">
                  <img src="/assets/Digital-left.png" alt="" width={"30%"} />
                  <StyledBox width={"30%"}>
                    <img src="/assets/Digital-center.png" alt="" width={"100%"} />
                    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                      <StyledFlexBox onClick={() => window.location = " https://apps.apple.com/tw/app/超有品智能平台-提升業務效率/id6479354795"} style={{ width: "100%", height: 93.90, background: 'linear-gradient(90deg, #911AAD 0%, #4C1A57 100%)', borderRadius: 32, justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                        <div style={{ color: 'white', fontSize: 46.23, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word' }} onClick={() => window.location = " https://apps.apple.com/tw/app/超有品智能平台-提升業務效率/id6479354795"}>立即下載APP</div>
                      </StyledFlexBox>
                      <StyledFlexBox fontSize="40px" justifyContent={"center"}>
                        開始體驗我們的服務
                      </StyledFlexBox>
                      <StyledFlexBox fontSize="22px" justifyContent={"center"}>
                        —超滿足有限公司—
                      </StyledFlexBox>
                    </div>
                  </StyledBox>
                  <img src="/assets/Digital-right.png" alt="" width={"30%"} />
                </StyledFlexBox> :
                <><StyledBox width={"100%"}>
                  <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                    <StyledFlexBox onClick={() => window.location = " https://apps.apple.com/tw/app/超有品智能平台-提升業務效率/id6479354795"} style={{ width: "100%", height: 93.90, background: 'linear-gradient(90deg, #911AAD 0%, #4C1A57 100%)', borderRadius: 32, justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                      <div style={{ color: 'white', fontSize: 40, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word' }} onClick={() => window.location = " https://apps.apple.com/tw/app/超有品智能平台-提升業務效率/id6479354795"}>立即下載APP</div>
                    </StyledFlexBox>
                    <StyledFlexBox fontSize="22px" justifyContent={"center"}>
                      —超滿足有限公司—
                    </StyledFlexBox>
                  </div>
                </StyledBox>
                  <StyledFlexBox justifyContent="space-between" pt={"2rem"}>
                    <img src="/assets/Digital-left.png" alt="" width={"50%"} />

                    <img src="/assets/Digital-right.png" alt="" width={"50%"} />

                  </StyledFlexBox></>}
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Digital;
