import { Parallax } from "react-parallax";
//import Hero from "./Hero";
import Header from "../../Layout/OrderHeader/Header";
import { StyledFlexBox, StyledBox } from "../../styles/Shared.styles";

import Footer from "./Footer";
import React, { useState, useEffect } from "react";
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

const Landing = () => {
  const [isScreenTooSmall, setIsScreenTooSmall] = useState(0);
  const isSmallScreen = window.innerWidth < 768;
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  console.log(queryString.parse(location.search))
  const type = queryParams.type || '';
  const id = queryParams.id || '';
  const time = queryParams.time || '';
  const year = time.substring(0, 4);
  const month = time.substring(4, 6);
  const day = time.substring(6, 8);
  const hour = time.substring(8, 10);
  const minute = time.substring(10, 12);
  const formattedTime = `${year}/${month}/${day} ${hour}:${minute}`;

  useEffect(() => {
    if (window.innerWidth <= 1024 && window.innerWidth >= 768)
      setIsScreenTooSmall(1);
    else if (window.innerWidth < 768)
      setIsScreenTooSmall(2);
  }, []);
  return (
    <div className="container mt-header">
      <Header />
      <StyledFlexBox
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100vh">
        <StyledBox>
          <StyledBox textAlign="center">
            <img src={type == 0 ? "/assets/orderHeaderLogoDone.svg" : "/assets/orderHeaderLogoError.svg"} alt="" width={"120px"} />
          </StyledBox>
          <StyledBox pt={3} textAlign="center">
            <h2>
              {type == 0 ? "購買完成" : "購買失敗..."}
            </h2>
          </StyledBox>
          {type == 0 ?
            <>
              <StyledBox pt={2} textAlign="center">
                <h4>
                  謝謝老闆，感激訂購！
                </h4>
              </StyledBox>
              <StyledBox pt={4} textAlign="center">
                <h4>
                  您的訂單編號為 {id}，預計抵達時間為 {formattedTime}
                </h4>
                <h4>
                  若有任何疑問，請洽超有品客服，謝謝！
                </h4>
              </StyledBox>
            </>
            : <>
              <StyledBox pt={2} textAlign="center">
                <h4>
                  購買失敗...
                </h4>
              </StyledBox>
              <StyledBox pt={4} textAlign="center">
                <h4>
                  您的訂單可能部分欄位未填寫完全，或是格式不符合規範，建議您重新修改喔！
                </h4>
                <h4>
                  若有任何疑問，請洽超有品客服，謝謝！
                </h4>
              </StyledBox>
            </>}
          <StyledFlexBox pt={4} textAlign="center" justifyContent="space-between">
            <button className="orderDoneButton">
              修改訂單
            </button>
            <button className="orderDoneListButton">
              回到首頁
            </button>
          </StyledFlexBox>

        </StyledBox>
      </StyledFlexBox>
      {isScreenTooSmall !== 2 &&
        <StyledFlexBox justifyContent="space-between" width="100%" style={{ position: "fixed", bottom: 0 }}>
          <StyledFlexBox justifyContent="space-between" width="100%" px={5} py={4} alignItems="center">
            <StyledBox >
              <img src="/assets/orderHeaderLogo.svg" alt="" width={"150px"} />
              <h4>
                最聰明的食材供應平台
              </h4>
            </StyledBox>
            <StyledFlexBox >
              <StyledBox mx={3}>
                公司介紹
              </StyledBox >
              <StyledBox mx={3}>
                隱私條款
              </StyledBox>
              <StyledBox mx={3}>
                聯絡客服
              </StyledBox>

            </StyledFlexBox>
          </StyledFlexBox>

        </StyledFlexBox>}

    </div>
  );
};

export default Landing;
