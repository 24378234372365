import MailModal from "../../Components/MailModal/MailModal";
import "./styles/Footer.css";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { StyledFlexBox, StyledBox } from "../Styles/Shared.styles";

const Footer = () => {

  //const [disabled, setDisabled] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isScreenTooSmall, setIsScreenTooSmall] = useState(0);
  useEffect(() => {
    if (window.innerWidth <= 1024 && window.innerWidth >= 768)
      setIsScreenTooSmall(1);
    else if (window.innerWidth < 768)
      setIsScreenTooSmall(2);
  }, []);
  return (
    <div id="footer" className="footer">

      <Fade duration={700} collapse top>

        <StyledFlexBox width="100%" justifyContent="center">
          <StyledFlexBox px={"2rem"} justifyContent="space-between" textAlign="center" style={{ width: '100%', maxWidth: "700px" }} py={5} mt={3}>
            <img src="/assets/footer.png" alt="" />
            <StyledBox mt={5}>
              <img src="/assets/products_logo.svg" alt="" width={"200px"} />
              <StyledBox textAlign="center" pt={2} color="#911AAD" fontSize={"20px"}>
                SUPER U PIN
              </StyledBox>
              <StyledBox textAlign="center" pt={2} color="black" fontSize={"32px"} style={{ fontWeight: 600 }} >
                生鮮供應整合App
              </StyledBox>
              <StyledFlexBox onClick={() => window.location = " https://apps.apple.com/tw/app/超有品智能平台-提升業務效率/id6479354795"} mt={5} style={{ width: "100%", height: 63.90, background: 'linear-gradient(90deg, #911AAD 0%, #4C1A57 100%)', borderRadius: 32, justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                <div style={{ color: 'white', fontSize: 30, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word' }} >立即下載app</div>
              </StyledFlexBox>
              <StyledFlexBox fontSize="30px" justifyContent={"center"} pt={3}>
                開始體驗我們的服務
              </StyledFlexBox>
            </StyledBox>

          </StyledFlexBox>
        </StyledFlexBox>
      </Fade>
    </div >
  );
};

export default Footer;
