import { StyledFlexBox, StyledBox, StyledText } from "../../styles/Shared.styles";
import { fetchShoppingList, updataShoppingList, updataPurchase } from "../../Apis/Shopping";
import React, { useState, useEffect, useRef } from "react";
import Form from "./Form";


const Landing = ({ lineId }) => {
  const [sevenStore, setSevenStore] = useState('');
  const [sevenAddress, setSevenAddress] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [comment, setComment] = useState('');
  const [specialCode, setSpecialCode] = useState('');
  const [payType, setPayType] = useState(''); // 預設值為 DIRECT

  const [type, setType] = useState(0);
  const [data, setData] = useState(0);
  const [total, setTotal] = useState(0); // 新增 total 狀態
  const [paymentResult, setPaymentResult] = useState('');
  const [tappayState, setTappayState] = useState(false);
  const [prime, setPrime] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [errors, setErrors] = useState({
    payType: false,
    sevenStore: false,
    sevenAddress: false,
    name: false,
    phone: false,
    email: false
  });
  const incrementValue = (index) => {
    const newData = [...data]; // 克隆一份 data 狀態的副本
    newData[index].count += 1; // 增加特定產品的數量
    setData(newData); // 更新 data 狀態
    const params = {
      item_requests: data,
    };
    let totalPrice = 0;

    newData.forEach(item => {
      totalPrice += item.product_price * item.count;
    });
    setTotal(totalPrice)
    updataShoppingList(lineId, params).then((data) => {
    });
  };

  // 減少特定產品數量的函式
  const decrementValue = (index) => {
    const newData = [...data]; // 克隆一份 data 狀態的副本
    newData[index].count = Math.max(0, newData[index].count - 1); // 減少特定產品的數量

    // 如果數量為 0 或小於 0，則從陣列中移除該產品
    if (newData[index].count <= 0) {
      newData.splice(index, 1);
    }

    setData(newData); // 更新 data 狀態
    let totalPrice = 0;

    newData.forEach(item => {
      totalPrice += item.product_price * item.count;
    });
    setTotal(totalPrice)
    // 呼叫 API 更新購物清單
    const params = {
      item_requests: newData, // 更新後的資料
    };
    updataShoppingList(lineId, params).then((responseData) => {
      // 在這裡處理更新成功後的邏輯，如果需要的話
    });
  };

  useEffect(() => {
    fetchShoppingList(lineId).then((data) => {
      if (data.code === 20000) {
        setData(data.data.item_responses)
        let totalPrice = 0;

        data.data.item_responses.forEach(item => {
          totalPrice += item.product_price * item.count;
        });
        setTotal(totalPrice)

      }
    });
    window.TPDirect.setupSDK(139914, 'app_jZiZ1wnjhFm79PbRgxKdft5Muw4OSR5TgyEZJf8CKNn25XeoR5CNFxmO0dZT', 'sandbox');

  }, []);

  function setTapPay() {
    if (!tappayState) {
      window.TPDirect.setupSDK(139914, 'app_jZiZ1wnjhFm79PbRgxKdft5Muw4OSR5TgyEZJf8CKNn25XeoR5CNFxmO0dZT', 'sandbox');
      window.TPDirect.card.setup({
        fields: {
          number: {
            element: '#card-number',
            placeholder: '**** **** **** ****'
          },
          expirationDate: {
            element: document.getElementById('card-expiration-date'),
            placeholder: 'MM / YY'
          },
          ccv: {
            element: '#card-ccv',
            placeholder: '後三碼'
          }
        },
        styles: {
          'input': {
            'color': 'gray'
          },
          '.valid': {
            'color': 'green'
          },
          '.invalid': {
            'color': 'red'
          }
        }
      });
      setTappayState(true)
    }

  }
  function getPrime() {
    // 取得 TapPay Fields 的 status1
    var tappayStatus = window.TPDirect.card.getTappayFieldsStatus();
    // 確認是否可以 getPrime
    if (tappayStatus.canGetPrime === false) {
      alert('can not get prime');
      return;
    }
    window.TPDirect.card.getPrime((result) => {
      if (result.status !== 0) {
        setPrime()
        alert('信用卡錯誤');
        return;
      }
      else {
        setPrime(result.card.prime)
        setType(2)
      }
    })
  }
  function checkout() {
    setType(3)
    const params = {
      seven_store: sevenStore,
      seven_address: sevenAddress,
      name: name,
      phone: phone,
      email: email,
      comment: comment,
      special_code: specialCode,
      pay_type: payType,
      item_requests: data
    };
    updataPurchase(lineId, prime, params).then((data) => {
      setType(4)
      window.close();
    });
  }

  useEffect(() => {
    // 電話號碼檢查：必須以0開頭，並且為9或10位數字
    const isPhoneValid = /^0\d{8,9}$/.test(phone);

    // 信箱格式檢查：至少包含一個@
    const isEmailValid = email.includes('@');

    // 更新按鈕狀態
    setIsButtonDisabled(
      !payType ||
      (!sevenStore && !sevenAddress) ||
      !name ||
      !isPhoneValid ||
      !isEmailValid
    );

    // 更新錯誤狀態
    setErrors({
      payType: !payType,
      sevenStore: !(sevenStore || sevenAddress),
      sevenAddress: !(sevenStore || sevenAddress),
      name: !name,
      phone: !isPhoneValid,
      email: !isEmailValid
    });
  }, [specialCode, payType, sevenStore, sevenAddress, name, phone, email]); return (
    <div className="container mt-header">
      <div style={{ display: type !== 0 && "none", width: "100vw" }}>
        {data.length > 0 ? data.map((product, index) => (
          <StyledFlexBox key={index} width="100%" px={3} pt={2}>
            <StyledBox>
              <img src={`/assets/product${product.product_id}.png`} alt="" width={"100px"} />
            </StyledBox>
            <StyledFlexBox
              px={3}
              py={2}
              width="100%"
              style={{
                flexDirection: "column",
              }}
            >
              <StyledText fontSize="24px" fontWeight="700">
                {product.product_name}
              </StyledText>
              <StyledFlexBox justifyContent="space-between" pt={3} width="100%" alignItems="center">
                <StyledText fontSize="20px" fontWeight="700">
                  ${product.product_price}
                </StyledText>
                <StyledFlexBox className="cartButtonNumber">
                  <button onClick={() => decrementValue(index)} className="cartButton">-</button>
                  <input
                    type="number"
                    value={product.count}
                    onChange={(e) => {
                      // 處理數量改變的邏輯
                    }}
                    className="input-field "
                    style={{
                      width: "30px",
                      textAlign: "center",
                    }}
                  />
                  <button onClick={() => incrementValue(index)} className="cartButton">+</button>
                </StyledFlexBox>
              </StyledFlexBox>
            </StyledFlexBox>
          </StyledFlexBox>
        )) :
          <StyledFlexBox width="100%" px={3} pt={2} justifyContent="center">目前購物車為空</StyledFlexBox>}
        <StyledFlexBox px={3} width="100%">
          <hr style={{
            width: "100%",
            border: "1px solid rgba(0,0,0,0.2)"
          }} />
        </StyledFlexBox>
        <StyledFlexBox px={3} width="100%" mb={"124px"}>
          <hr style={{
            width: "100%",
            border: "1px solid rgba(0,0,0,0.2)"
          }} />
        </StyledFlexBox>
        <div className="lineCartfixed" style={{ width: "100%" }}>
          <StyledFlexBox px={3} width="100%">
            <hr style={{
              width: "100%",
              border: "1px solid rgba(0,0,0,0.2)"
            }} />
          </StyledFlexBox>
          <StyledFlexBox fontSize="20px" fontWeight="700" justifyContent="space-between" width="100%" px={3} pt={2}>
            <div>總計(含稅)
            </div>
            <div> ${total}
            </div>
          </StyledFlexBox>
          <StyledFlexBox justifyContent="center" pt={2}>
            <button className="lineCartButton" onClick={() => setType(1)}>
              開始結帳
            </button>
          </StyledFlexBox>

        </div>
      </div>
      <div style={{ display: type !== 1 && "none", width: "100vw" }}>
        <StyledBox width="100%" justifyContent="space-between" px={4} py={2} fontSize="16px">
          <StyledText>
            專屬折扣碼
          </StyledText>
          <StyledText mt={1}>
            <input value={specialCode} onChange={(e) => setSpecialCode(e.target.value)}
              placeholder="請輸入專屬折扣碼"
              type="text" style={{ width: "100%", padding: "0.5rem 0.5rem 0.5rem", border: "1px solid #E9E9E9", borderRadius: "10px" }}></input>
          </StyledText>
        </StyledBox>
        <StyledBox width="100%" justifyContent="space-between" px={4} py={2} fontSize="16px">
          <StyledText>
            付款方式
          </StyledText>
          <StyledText mt={1}>
            <select value={payType}
              onChange={(e) => {
                setPayType(e.target.value);
                setTapPay()
              }}

              style={{
                padding: "2px 4px", display: "flex",
                alignItems: "center", height: "40px",
                borderRadius: "10px",
                boxShadow: "0", fontSize: "14px", marginLeft: "1px",
                border: "1px solid #E9E9E9",
                width: "100%"
              }}
            >
              <option value="">付款方式</option >
              <option value="DIRECT">信用卡</option >
            </select>
            {errors.payType && <span style={{ color: 'red' }}>請選擇付款方式</span>}

          </StyledText>

          <div className="tpfield" id="card-number"
            style={{
              padding: "2px 4px", display: "flex",
              alignItems: "center", height: "40px",
              borderRadius: "10px",
              boxShadow: "0", fontSize: "14px", marginLeft: "1px",
              border: "1px solid #E9E9E9",
              width: "100%"
            }}></div>
          <div className="tpfield" id="card-expiration-date" style={{
            padding: "2px 4px", display: "flex",
            alignItems: "center", height: "40px",
            borderRadius: "10px",
            boxShadow: "0", fontSize: "14px", marginLeft: "1px",
            border: "1px solid #E9E9E9",
            width: "100%"
          }}></div>
          <div className="tpfield" id="card-ccv" style={{
            padding: "2px 4px", display: "flex",
            alignItems: "center", height: "40px",
            borderRadius: "10px",
            boxShadow: "0", fontSize: "14px", marginLeft: "1px",
            border: "1px solid #E9E9E9",
            width: "100%"
          }}></div>

        </StyledBox>
        <StyledBox width="100%" justifyContent="space-between" px={4} py={2} fontSize="16px">
          <StyledText>
            7-11取貨門市
          </StyledText>
          <StyledText mt={1}>
            <input
              value={sevenStore}
              onChange={(e) => setSevenStore(e.target.value)}
              placeholder="請輸入取貨門市"
              type="text"
              style={{ width: "100%", padding: "0.5rem 0.5rem 0.5rem", border: "1px solid #E9E9E9", borderRadius: "10px" }}
            />
            {errors.sevenStore && <span style={{ color: 'red' }}>請填入門市</span>}
            <input
              value={sevenAddress}
              onChange={(e) => setSevenAddress(e.target.value)}
              placeholder="請輸入7-11門市地址"
              type="text"
              style={{ width: "100%", marginTop: "0.5rem", padding: "0.5rem 0.5rem 0.5rem", border: "1px solid #E9E9E9", borderRadius: "10px" }}
            />
            {errors.sevenAddress && <span style={{ color: 'red' }}>請填入門市地址</span>}

          </StyledText>
        </StyledBox>
        <StyledBox width="100%" justifyContent="space-between" px={4} py={2} fontSize="16px">
          <StyledText>
            收件者姓名
          </StyledText>
          <StyledText mt={1}>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="請輸入姓名"
              type="text"
              style={{ width: "100%", padding: "0.5rem 0.5rem 0.5rem", border: "1px solid #E9E9E9", borderRadius: "10px" }}
            />
            {errors.name && <span style={{ color: 'red' }}>請填入姓名</span>}
          </StyledText>
        </StyledBox>
        <StyledBox width="100%" justifyContent="space-between" px={4} py={2} fontSize="16px">
          <StyledText>
            手機
          </StyledText>
          <StyledText mt={1}>
            <input
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="請輸入手機號碼"
              type="text"
              style={{ width: "100%", padding: "0.5rem 0.5rem 0.5rem", border: "1px solid #E9E9E9", borderRadius: "10px" }}
            />
            {errors.phone && <span style={{ color: 'red' }}>請填入手機</span>}
          </StyledText>
        </StyledBox>
        <StyledBox width="100%" justifyContent="space-between" px={4} py={2} fontSize="16px">
          <StyledText>
            信箱
          </StyledText>
          <StyledText mt={1}>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="請輸入信箱"
              type="email"
              style={{ width: "100%", padding: "0.5rem 0.5rem 0.5rem", border: "1px solid #E9E9E9", borderRadius: "10px" }}
            />
            {errors.email && <span style={{ color: 'red' }}>請填入信箱</span>}
          </StyledText>
        </StyledBox>
        <StyledBox width="100%" justifyContent="space-between" px={4} py={2} fontSize="16px" mb={"190px"}>
          <StyledText>
            備註
          </StyledText>
          <StyledText mt={1}>
            <input
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="請輸入備註"
              type="text"
              style={{ width: "100%", padding: "0.5rem 0.5rem 0.5rem", border: "1px solid #E9E9E9", borderRadius: "10px" }}
            />
          </StyledText>
        </StyledBox>
        <div className="lineCartfixed" style={{ width: "100%" }}>
          <StyledFlexBox px={3} width="100%">
            <hr style={{
              width: "100%",
              border: "1px solid rgba(0,0,0,0.2)"
            }} />
          </StyledFlexBox>
          <StyledFlexBox fontSize="20px" fontWeight="700" justifyContent="space-between" width="100%" px={3} pt={2}>
            <div>總計(含稅)
            </div>
            <div> ${total}
            </div>
          </StyledFlexBox>
          <StyledFlexBox justifyContent="center" pt={2}>
            <button
              disabled={isButtonDisabled}
              style={{
                cursor: isButtonDisabled && "pointer",
                backgroundColor: isButtonDisabled ? '#ccc' : '#FFD600'
              }} className="lineCartButton" onClick={() => getPrime()}>
              下一步
            </button>
          </StyledFlexBox>
          <StyledFlexBox justifyContent="center" pt={2}>
            <button style={{
              cursor: "pointer"
            }} className="lineCartButton back" onClick={() => {
              setTappayState(false);
              setType(0)
              setPayType('')
            }}>
              上一步
            </button>
          </StyledFlexBox>
        </div>
      </div>

      <div style={{ display: type !== 2 && "none", width: "100vw" }}>
        <StyledBox background="#F8F8F8" width="100%" justifyContent="space-between" px={4} py={2} fontSize="16px">
          費用計算
        </StyledBox>
        <StyledBox px={3} width="100%"  >
          {data && data.map((product, index) => (
            <StyledFlexBox key={index} width="100%" pt={2}>
              <StyledFlexBox
                py={2}
                width="100%"

                justifyContent="space-between"
              >
                <StyledText fontSize="16px" >
                  {product.product_name} ({product.count}件)
                </StyledText>
                <StyledText fontSize="16px" >
                  ＄{product.product_price * product.count}
                </StyledText>
              </StyledFlexBox>
            </StyledFlexBox>
          ))}
          <StyledFlexBox width="100%" pt={2}>
            <StyledFlexBox
              py={2}
              width="100%"
              justifyContent="space-between"
            >
              <StyledText fontSize="16px" >
                運費
              </StyledText>
              <StyledText fontSize="16px" >
                ＄0
              </StyledText>
            </StyledFlexBox>

          </StyledFlexBox>
          <StyledFlexBox width="100%">
            <hr style={{
              width: "100%",
              border: "1px solid rgba(0,0,0,0.2)"
            }} />
          </StyledFlexBox>
          <StyledFlexBox width="100%" pt={2}>
            <StyledFlexBox
              py={2}
              width="100%"
              justifyContent="space-between"
            >
              <StyledText fontSize="16px" >
                總計(含稅)
              </StyledText>
              <StyledText fontSize="16px" >
                ＄{total}
              </StyledText>
            </StyledFlexBox>

          </StyledFlexBox>
        </StyledBox>
        <StyledBox background="#F8F8F8" width="100%" justifyContent="space-between" px={4} py={2} fontSize="16px">
          購買資訊
        </StyledBox>
        <StyledBox px={3} width="100%" mb={"124px"}>
          <StyledBox width="100%" pt={2}>
            <StyledFlexBox
              py={2}
              width="100%"
              justifyContent="space-between"
            >
              <StyledText fontSize="16px" >
                付款資訊
              </StyledText>
              <StyledText fontSize="16px" >
                {payType === "DIRECT" && "信用卡"}
              </StyledText>
            </StyledFlexBox>
            <StyledFlexBox
              py={2}
              width="100%"
              justifyContent="space-between"
            >
              <StyledText fontSize="16px" >
                收貨地址
              </StyledText>
              <StyledText fontSize="16px" >
                {sevenStore}
                <br />
                {sevenAddress}
              </StyledText>
            </StyledFlexBox>
            <StyledFlexBox
              py={2}
              width="100%"
              justifyContent="space-between"
            >
              <StyledText fontSize="16px" >
                收件者姓名
              </StyledText>
              <StyledText fontSize="16px" >
                {name}
              </StyledText>
            </StyledFlexBox>
            <StyledFlexBox
              py={2}
              width="100%"
              justifyContent="space-between"
            >
              <StyledText fontSize="16px" >
                手機
              </StyledText>
              <StyledText fontSize="16px" >
                {phone}
              </StyledText>
            </StyledFlexBox>
            <StyledFlexBox
              py={2}
              width="100%"
              justifyContent="space-between"
            >
              <StyledText fontSize="16px" >
                信箱
              </StyledText>
              <StyledText fontSize="16px" >
                {email}
              </StyledText>
            </StyledFlexBox>
            <StyledFlexBox
              py={2}
              width="100%"
              justifyContent="space-between"
              mb={"180px"}
            >
              <StyledText fontSize="16px" >
                備註
              </StyledText>
              <StyledText fontSize="16px" >
                {comment}
              </StyledText>
            </StyledFlexBox>
          </StyledBox>
        </StyledBox>
        <div className="lineCartfixed" style={{ width: "100%" }}>
          <StyledFlexBox px={3} width="100%">
            <hr style={{
              width: "100%",
              border: "1px solid rgba(0,0,0,0.2)"
            }} />
          </StyledFlexBox>
          <StyledFlexBox fontSize="20px" fontWeight="700" justifyContent="space-between" width="100%" px={3} pt={2}>
            <div>總計(含稅)
            </div>
            <div> ${total}
            </div>
          </StyledFlexBox>
          <StyledFlexBox justifyContent="center" pt={2}>
            <button className="lineCartButton" onClick={() => {
              checkout()
            }}>
              確定購買
            </button>
          </StyledFlexBox>
          <StyledFlexBox justifyContent="center" pt={2}>
            <button className="lineCartButton back" onClick={() => setType(1)}>
              上一步
            </button>
          </StyledFlexBox>
        </div>
      </div >
      <div style={{ display: type !== 3 && "none", width: "100vw" }}>
        <StyledFlexBox justifyContent="center" alignItems="center" style={{
          height: "90vh"
        }}>
          <StyledBox>
            <StyledFlexBox width="100%" justifyContent="center" alignItems="center" pt={3}>
              <img src="/assets/over.svg" alt="" className="trophy" />
            </StyledFlexBox>
            <StyledFlexBox fontSize="14px" color="#2F2F2F" width="100%" justifyContent="center" pt={3}>
              購買中請稍後
            </StyledFlexBox>
            <StyledFlexBox fontSize="12px" color="#828282" width="100%" justifyContent="center" pt={3}>
              感謝您的下單
            </StyledFlexBox>
          </StyledBox>
        </StyledFlexBox>
      </div>
      <div style={{ display: type !== 4 && "none", width: "100vw" }}>
        <StyledFlexBox justifyContent="center" alignItems="center" style={{
          height: "90vh"
        }}>
          <StyledBox>
            <StyledFlexBox width="100%" justifyContent="center" alignItems="center" pt={3}>
              <img src="/assets/over.svg" alt="" className="trophy" />
            </StyledFlexBox>
            <StyledFlexBox fontSize="14px" color="#2F2F2F" width="100%" justifyContent="center" pt={3}>
              完成購買
            </StyledFlexBox>
            <StyledFlexBox fontSize="12px" color="#828282" width="100%" justifyContent="center" pt={3}>
              感謝您的下單
            </StyledFlexBox>
            <StyledFlexBox fontSize="14px" color="#4F4F4F" width="100%" justifyContent="center" pt={3}>
              訂單編號：AU31836619
            </StyledFlexBox>
            <StyledFlexBox fontSize="12px" color="#4F4F4F" width="100%" justifyContent="center" pt={3}>
              將發送訂單完成信至您的信箱
            </StyledFlexBox>
            <StyledFlexBox fontSize="12px" color="#4F4F4F" width="100%" justifyContent="center" pt={3}>
              若有任何問題，請洽LINE客服，謝謝！
            </StyledFlexBox>
          </StyledBox>
        </StyledFlexBox>
      </div>
    </div>
  );
};

export default Landing;
