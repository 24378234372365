import { Parallax } from "react-parallax";
//import Hero from "./Hero";

import Footer from "./Footer";
import React, { useState, useEffect } from "react";

const Landing = () => {
  const [isScreenTooSmall, setIsScreenTooSmall] = useState(0);
  const isSmallScreen = window.innerWidth < 768;
  useEffect(() => {
    if (window.innerWidth <= 1024 && window.innerWidth >= 768)
      setIsScreenTooSmall(1);
    else if (window.innerWidth < 768)
      setIsScreenTooSmall(2);
  }, []);
  return (
    <div className="container mt-header">

      <Footer />

    </div>
  );
};

export default Landing;
