import React from "react";
import { Parallax } from "react-parallax";
import { StyledFlexBox, StyledBox } from "../Styles/Shared.styles";
import Hero from "../Landing/Hero";
import { Col, Container, Row } from "react-bootstrap";
import { Fade } from "react-reveal";
const Privacy = () => {
    return (
        <div id="footer" className="footer">
            <Parallax
                bgImage={"/assets/index.png"}
                strength={500}
            >
                <Hero />
            </Parallax>
            {/* 法律条款 */}
            <StyledFlexBox my={5}>
                <StyledBox width="100%" maxWidth="900px" mx="auto" fontSize="22px" >
                    <h2>隱私條款</h2>
                    <p style={{ fontSize: "16px" }}>用戶資料收集：尊重用戶隱私，收集個人資料如姓名、聯絡資訊、付款信息等，用於提供服務和確保平台運作。</p>
                    <p style={{ fontSize: "16px" }}>資料使用：利用用戶資料處理訂單、提供客戶支持、改進服務，並進行更新和促銷通知。</p>
                    <p style={{ fontSize: "16px" }}>資料分享：可能與供應商和合作夥伴分享用戶資料，以確保訂單順利交付，並會保護資料安全。</p>
                    <p style={{ fontSize: "16px" }}>用戶權限：用戶有權存取、更正、刪除或限制個人資料的使用，可聯繫客戶支持行使這些權利。 Cookie和追蹤技術：使用Cookie和類似技術收集資訊，以改善服務，用戶可在瀏覽器中管理Cookie設置。</p>
                </StyledBox>
            </StyledFlexBox>
            <StyledFlexBox my={5}>
                <StyledBox width="100%" maxWidth="900px" mx="auto" fontSize="22px">
                    <h2>免責聲明</h2>
                    <p style={{ fontSize: "16px" }}>食材品質：無法保證所有供應商的食材品質，建議用戶自行審核符合自身需求和標準。</p>
                    <p style={{ fontSize: "16px" }}>供應鏈中斷：盡力確保平台穩定運作，但無法完全排除供應鏈中斷風險，在此情況下將儘速解決問題。</p>
                    <p style={{ fontSize: "16px" }}>使用者行為：不對用戶間的行為負責，鼓勵遵守法律規定，尊重他人權利和隱私。</p>
                    <p style={{ fontSize: "16px" }}>責任限制：超有品生鮮供應不對任何直接或間接損失或損害負責，包括但不限於利潤損失、商譽損害、名譽損害或資料損毀等。</p>
                    <p style={{ fontSize: "16px" }}>修改權利:超有品生鮮供應保有視情形修改隱私條款、免責合約和服務條款等內容之權利，且在修改後如客戶使用我們的平台服務，即表示同意並接受修改的內容。</p>
                </StyledBox>
            </StyledFlexBox>
            <StyledFlexBox my={5}>
                <StyledBox width="100%" maxWidth="900px" mx="auto" fontSize="22px">
                    <h2>服務條款</h2>
                    <p style={{ fontSize: "16px" }}>用戶資格：您必須年滿法定成年年齡，並擁有合法權利使用我們的服務。您同意提供準確的個人資料以註冊和使用我們的平台。</p>
                    <p style={{ fontSize: "16px" }}>服務使用：使用服務時應遵守相關法律規定和使用政策。</p>
                    <p style={{ fontSize: "16px" }}>支付條款：同意支付所有訂單相關費用，包括商品價格和交易手續費。 服務變更和終止：保留隨時更改或終止服務的權利，會提前通知用戶。</p>
                    <p style={{ fontSize: "16px" }}>知識產權：平台上所有內容和知識產權歸屬超有品生鮮供應或合作夥伴。 爭議解決：任何爭議適用台灣法律，並在台灣法院解決。</p>
                </StyledBox>
            </StyledFlexBox>
            <StyledFlexBox my={5}>
                <StyledBox width="100%" maxWidth="900px" mx="auto" fontSize="22px">
                    <h2>退(換)貨條款</h2>
                    <p style={{ fontSize: "16px" }}>一、  平台方應依約定交付產品之日期及方式，並將產品交付消費者，消費者於當日驗收過程中發現瑕疵或不合約定之產品者，平台方應無條件盡速取回換貨，並當日交付合約約定之產品。 </p>
                    <p style={{ fontSize: "16px" }}>二、  在指定時間內如產品不符規格標準或其他情形需辦理換貨時，只要在規定時間通知商品缺漏或損耗,通知後平台方應立即辦理換貨處理。如換貨之產品有危害身體健康、衛生安全等情形時，平台方得先行處理後再通知消費者，但有緊急必要而無法通知者除外。因換貨所衍生之費用及損失，均由平台方自行負擔。</p>
                    <p style={{ fontSize: "16px" }}>三、  平台方交付產品予消費者時，應做適當的安全預防措施，以防人員傷亡或財物損害，如造成人員傷亡或財物損害,平台方均須賠償相關一切損失(備註:有時人員或財物不是消費者的，可能是第三人的。)</p>
                </StyledBox>
            </StyledFlexBox>
            <Fade duration={700} collapse top>
                <StyledFlexBox width="100%" justifyContent="center">
                    <StyledFlexBox px={"2rem"} justifyContent="space-between" textAlign="center" style={{ width: '100%', maxWidth: "700px" }} py={5} mt={3}>
                        <img src="/assets/footer.png" alt="" />
                        <StyledBox mt={5}>
                            <img src="/assets/products_logo.svg" alt="" width={"200px"} />
                            <StyledBox textAlign="center" pt={2} color="#911AAD" fontSize={"20px"}>
                                SUPER U PIN
                            </StyledBox>
                            <StyledBox textAlign="center" pt={2} color="black" fontSize={"32px"} style={{ fontWeight: 600 }} >
                                生鮮供應整合App
                            </StyledBox>
                            <StyledFlexBox onClick={() => window.location = " https://apps.apple.com/tw/app/超有品智能平台-提升業務效率/id6479354795"} mt={5} style={{ width: "100%", height: 63.90, background: 'linear-gradient(90deg, #911AAD 0%, #4C1A57 100%)', borderRadius: 32, justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                                <div style={{ color: 'white', fontSize: 30, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word' }}>立即下載app</div>
                            </StyledFlexBox>
                            <StyledFlexBox fontSize="30px" justifyContent={"center"} pt={3}>
                                開始體驗我們的服務
                            </StyledFlexBox>
                        </StyledBox>
                    </StyledFlexBox>
                </StyledFlexBox>
            </Fade>
            <div className="foot-wrap">
                <div className="bg-purple-dark">
                    <Container>
                        <Row>
                            <div className="bot-foot">
                                <p>Copyright 2024 Super u pin All Rights Reserved. <a style={{ color: "blue" }} href="privacy">隱私權政策</a>  <a style={{ color: "blue" }} href="/">首頁</a> </p>
                                <div className="social-div">
                                    <a href="https://line.me/R/ti/p/%40277bgmdt" target="_blank">
                                        <img src="/Assets/line.svg" alt="" />
                                    </a>
                                    <a href="https://facebook.com/qr?id=100082804461160" target="_blank">
                                        <img src="/Assets/fb.svg" alt="" />
                                    </a>
                                    <a href="https://www.instagram.com/classuptw/" target="_blank">
                                        <img src="/Assets/ig.svg" alt="" />
                                    </a>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </div>
            </div >
        </div>
    );
};

export default Privacy;
