import React, { useState, useEffect } from 'react';
import liff from '@line/liff';

const LiffComponent = () => {
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    const initializeLiff = async () => {
      try {
        await liff.init({ liffId: "YOUR_LIFF_ID" });
        if (liff.isLoggedIn()) {
          const userProfile = await liff.getProfile();
          setProfile(userProfile);
        } else {
          liff.login();
        }
      } catch (error) {
        console.error('Error initializing LIFF: ', error.message);
      }
    };

    initializeLiff();
  }, []);

  return (
    <div>
      {profile ? (
        <div>
          <img src={profile.pictureUrl} alt="Profile" />
          <p>Hello, {profile.displayName}!</p>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default LiffComponent;
